import React, { useEffect, useRef } from "react"
import { connect } from "react-redux";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import { getSisterStoreFilters } from "../../actions/DCConfigAction/DCConfigAction"
import { useState } from "react";
import MultiSelect from "../../../../components/Filters/MultiSelect";
import { handleSelectAll } from "../../../../utils/SelectAllHandler/SelectAllHander";
import { isEmpty } from "lodash";
import Select from "../../../../components/Filters/SingleSelect";

const FilterCard = (props) => {
    const { filterData, setAllowNextCard, filterValues, onFocusCall, index, closeCard, req, assort, disableEdit } = props
    const [departmentValue, setDepartmentValue] = useState([])
    const [seasonCode, setSeasonCode] = useState([])
    const [sisterStoreValue, setSisterStoreValue] = useState([])
    const [count, setCount] = useState(0)
    const [isDeptChange, setDeptChange] = useState(false)

    useEffect(() => {
        if (isEmpty(filterValues)) {
            setDepartmentValue([])
            setSeasonCode([])
            setSisterStoreValue([])
        }
        else {
            filterValues?.departmentValue && setDepartmentValue(filterValues?.departmentValue)
            filterValues?.seasonCode && setSeasonCode(filterValues?.seasonCode)
            filterValues?.sisterStoreValue && setSisterStoreValue(filterValues?.sisterStoreValue)
            if(!count && !assort[index]?.length) {
                props.getSisterStoreFilters({...req, "l2_name": filterValues?.departmentValue?.map(dept => dept.value).filter(dept => dept !== "*"), index})
            }
            setCount((pre) => pre + 1)
        }
    }, [filterValues])

    const onDepartmentChange = (val, event, that) => {
        let valuesArray = handleSelectAll(val, event, that);
        setDepartmentValue(valuesArray[1]);
        setSeasonCode([])
        props.getSisterStoreFilters({...req, "l2_name": valuesArray[1]?.map(dept => dept.value).filter(dept => dept !== "*"), index})
        setDeptChange(true)
    };

    const onStoreChange = (val) => {
        setSisterStoreValue(val);
        canAllowNextCard(departmentValue, seasonCode, val)
    };

    const onSeasonCodeChange = (val, event, that) => {
        let valuesArray = handleSelectAll(val, event, that);
        setSeasonCode(valuesArray[1]);
        canAllowNextCard(departmentValue, valuesArray[1], sisterStoreValue)
    };
    
    const canAllowNextCard = (dept, assort, sister) => {
        if(dept?.length && assort?.length && sister?.value) {
            setAllowNextCard(true, { seasonCode: assort, departmentValue: dept, sisterStoreValue: sister}, index)
        }
        else {
            setAllowNextCard(false, { seasonCode: assort, departmentValue: dept, sisterStoreValue: sister }, index)
        }
    }

    useEffect(() => {
        if(assort[index] && (!filterValues?.seasonCode?.length || isDeptChange) && departmentValue?.length) {
            setDeptChange(false)
            if(departmentValue?.length) {
                setSeasonCode(assort[index])
            }
            canAllowNextCard(departmentValue, assort[index], sisterStoreValue)
        }
    }, [assort])

    return (
        <div className="filter-card__container filter-set">
            {
                closeCard && !disableEdit &&
                <span className="close-card__icon" onClick={() => { closeCard(index) }}>
                    <i
                        className="fa fa-times text-right"
                        aria-hidden="true"
                    />
                </span>
            }
            <div className="p-field p-grid required mt-2">
                <label className="non-editable-labels p-col-fixed label-40">
                    Department
                </label>
                <div className="p-col w-50">
                    <MultiSelect
                        id="NEW_STORE_SELECTOR"
                        name="storeNumber"
                        placeholder="Select Department"
                        className="width-50"
                        dropdownValue={departmentValue}
                        onDropDownChange={onDepartmentChange}
                        optionsArray={filterData?.l2_name || []}
                        isDisabled={disableEdit}
                    />
                </div>
            </div>
            <div className="p-field p-grid required mt-4">
                <label className="non-editable-labels p-col-fixed label-40">
                    Assortment Indicator
                </label>
                <div className="p-col w-50">
                    <MultiSelect
                        id="NEW_STORE_SELECTOR"
                        name="seasoncode"
                        placeholder="Select Assort Indicator"
                        className="width-50"
                        dropdownValue={seasonCode}
                        onDropDownChange={onSeasonCodeChange}
                        optionsArray={assort[index] || []}
                        isDisabled={disableEdit}
                    />
                </div>
            </div>
            <div className="p-field p-grid required">
                <label className="non-editable-labels p-col-fixed label-40">
                    Sister Store
                </label>
                <div className="p-col w-50 font-weight-normal">
                    <Select
                        name="storeNumber"
                        value={sisterStoreValue}
                        onChange={onStoreChange}
                        placeholder="Select Sister Store"
                        options={filterData?.stores}
                        className="p-col"
                        isDisabled={disableEdit}
                    />
                </div>
            </div>
        </div>)
}

const mapStateToProps = ({ sisterStoreDCConfig }) => ({
    filterData: sisterStoreDCConfig.filterData,
    assort: sisterStoreDCConfig.assort
});

const mapDispatchToProps = (dispatch) => ({
    getSisterStoreFilters: (payload) => dispatch(getSisterStoreFilters(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterCard);