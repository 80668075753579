import React, { useEffect, useState } from 'react';
import "./Storedetailsconfig.css";
import { isEmpty } from 'lodash';
import Select from '../../../../components/Filters/SingleSelect';

const DcLeadTimeCard = (props) => {
    const { card, dcDetails, saveCard, index, allCards, approved } = props

    const [options, setOptions] = useState([])
    const [leadTime, setLeadTime] = useState()
    const [selectedDC, setSelectedDc] = useState({})
    

    useEffect(() => {
        if (!isEmpty(card)) {
            setLeadTime(card?.leadTime)
            setSelectedDc(card?.dc)
        }
        else {
            setLeadTime("")
            setSelectedDc({})
        }
        let options = dcDetails?.length ? dcDetails?.filter(dc => dc.value !== "*") : [];
        let alreadySelected = allCards?.map(c => c?.dc?.value)
        setOptions(index === 0 ? options : options?.filter(opt => !alreadySelected.includes(opt.value)))
    }, [card])

    const updateLeadTime = (value) => {
        value = Number(value)
        setLeadTime(value < 1 ? 1 : value)
        if (value > 0 && !isEmpty(selectedDC)) {
            saveCard(true, { selectedDC, leadTime: value }, index)
        }
    }

    const onDcChange = (val) => {
        setSelectedDc(val);
        if (leadTime > 0 && !isEmpty(val)) {
            saveCard(true, { selectedDC: val, leadTime }, index)
        }
    };


    return (
        <div className="filter-card__container filter-set ml-0" style={{ width: "20%" }}>
            <div className="p-field p-grid mt-4 flex-column">
                <label className="non-editable-labels p-col-fixed label-35">
                    DC
                </label>
                <div className="p-col">
                    <input
                        id="NEW_STORE_SELECTOR"
                        name="seasoncode"
                        // placeholder="Select DC"
                        className="p-col w-100 form-control ml-0"
                        value={selectedDC?.label || ""}
                        // onChange={onDcChange}
                        // options={options}
                        disabled
                    />
                </div>
            </div>
            <div className="p-field p-grid flex-column">
                <label className="non-editable-labels p-col-fixed w-auto">
                    Lead Time(In days)
                </label>
                <div className="p-col" style={{ marginTop: "1%" }}>
                    <input
                        id="NEW_STORE_SELECTOR"
                        // className="form-input form-control"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        // min={1}
                        className="p-col w-100 form-control ml-0"
                        type={"number"}
                        onChange={(e) => { updateLeadTime(e?.target?.value) }}
                        value={leadTime}
                        placeholder="Lead Time"
                        disabled={localStorage.getItem("group_id") != 1 || approved}
                        onKeyPress={(e) => e.key === "." && e.preventDefault()}
                    />
                </div>
            </div>
        </div>
    )
}

export default DcLeadTimeCard