import React from 'react'
import { Step, StepButton, Stepper } from "@material-ui/core";
import "./Stepper.css"

const StepperComponent = (props) => {

    const { steps, activeStep, heading, goBack, moveForward } = props

    return (
        <>
            <div className="stepper-container">

                <div className="container__header mb-0">
                    <span className="row">
                        <h1 className="fnt-lg fnt-bold ml-2" style={{ color: "#44677b" }}>
                            {heading.split("/")[0]}
                            {heading.split("/")[1] &&
                                <span className="fnt-lg fnt-bold ml-2" style={{ color: "black" }}>
                                    / {heading.split("/")[1]}
                                </span>
                            }
                        </h1>
                    </span>
                </div>
                <div className="col-md-12">
                    <Stepper
                        alternativeLabel
                        // nonLinear
                        activeStep={activeStep}
                        style={{ backgroundColor: "transparent" }}
                    >
                        {steps.map(s => {

                            return (
                                <Step key={s.label}>
                                    <StepButton style={{ fontSize: "20px" }}>
                                        <span style={{ fontSize: "13px" }}>{s.label}</span>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <>
                    {steps[activeStep]?.component}
                </>
            </div>
            <div className="text-center mt-4 mb-4">
                {
                    steps[activeStep]?.funcButtons?.map(btn =>
                        btn.isShow &&
                        <button className="btn btn-primary px-2 mr-4" onClick={btn.onClickHandler}>
                            {btn.name}
                        </button>
                    )
                }
                {
                    !steps[activeStep]?.isRelease &&
                    <button className="btn mr-4 px-2 undo-constraints-mode back-btn"
                        onClick={goBack} >
                        Back
                    </button>
                }
                {
                    !steps[activeStep]?.hideNext &&
                    <button disabled={steps[activeStep]?.disableNext} className="btn btn-primary px-2" onClick={moveForward}>
                        {steps[activeStep]?.nextButtonText ? steps[activeStep].nextButtonText : "Proceed"}
                    </button>
                }
            </div>
        </>
    )
}

export default StepperComponent