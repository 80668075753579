import { debounce } from "lodash";
import React, { useState, useEffect, useCallback, useRef } from "react";

const InputCell = React.memo(React.forwardRef(({ ...instance }, ref) => {
  const {
    updateMyData,
    formatter,
    row,
    column,
    type,
    value,
    disabled = false,
    inputType,
    isRoundOff,
    isWhole,
    max,
    min,
    step,
    roundOffOnChange,
    toRecalculate,
    enableRecalculateBtn,
    changeHandler,
    blurHandler,
    debouncedChangeHandler,
    isFixed,
    style,
    className,
    shouldOnBlur,
    noDecimal
    // otherProps,
  } = instance;
  const [inputValue, setInputValue] = useState(null);
  const [focusedValue, setFocusedValue] = useState(0);

  useEffect(() => {
    if (typeof formatter == "function")
      setInputValue(formatter({ value: value }));
    else {
      if (isRoundOff) {
        setInputValue(Math.round(value * 100) / 100);
      } else if (isWhole) {
        setInputValue(Math.round(value));
      } else if (isFixed) {
        setInputValue(Number(value).toFixed(isFixed))
      }
      else 
      setInputValue(value)
    }
  }, [value, formatter]);

  const debouncedChangedValue = useCallback(debounce((index,column,value,rowId,target,row) => {
    debouncedChangeHandler(index,column,value,rowId,target,row)
  },1000),[]);
  
  // useEffect(() => {
  //   if(otherProps?.isPercent && value > 100)
  //   setInputValue(100)
  //   if(otherProps?.isPercent == false && value > instance.row.original?.total_inventory )
  //   setInputValue(instance.row.original?.total_inventory)
  // },[otherProps?.isPercent])

  return (
    <div style={{ display: "flex", width: "inherit" }}>
      {inputType === "dollar" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "38px",
            padding: "6px",
          }}
        >
          ${" "}
        </div>
      )}
      <input
        className={className}
        ref={ref || null}
        style={{
          height: "38px",
          paddingLeft: "6px",
          boxSizing: "border-box",
          width: "100%",
          ...style
        }}
        value={inputValue}
        max={max}
        min={min}
        step={step || ""}
        disabled={disabled}
        type={type || "text"}
        onBlur={(e) => {
          if(blurHandler){
            blurHandler(row.index,
            column.id,
            e.target.value,
            row.id,
            e.target.idTemp,
            row,
            focusedValue)
          }
            if(shouldOnBlur) {
              if(max || max==0){
                e.target.value = Math.min(max,e.target.value) 
              }
              if(min || min==0){
                e.target.value = Math.max(min,e.target.value) 
              }
              setInputValue(e.target.value);
              updateMyData(
                row.index,
                column.id,
                e.target.value,
                row.id,
                e.target.idTemp,
                row
              );
              if(changeHandler){
                changeHandler(row.index,
                column.id,
                e.target.value,
                row.id,
                e.target.idTemp,
                row)
              }
              if(debouncedChangeHandler){
                debouncedChangedValue(row.index,
                column.id,
                e.target.value,
                row.id,
                e.target.idTemp,
                row)
              }
            }
        }}
        onFocus={(e) => {
          setFocusedValue(e.target.value)
        }}
        onChange={(e) => {
          if(!shouldOnBlur) {
            if(roundOffOnChange){
              e.target.value = Math.round(e.target.value)
            }
            if(max || max==0){
              e.target.value = Math.min(max,e.target.value) 
            }
            if(min || min==0){
              e.target.value = Math.max(min,e.target.value) 
            }
          }
          setInputValue(e.target.value);
          updateMyData(
            row.index,
            column.id,
            e.target.value,
            row.id,
            e.target.idTemp,
            row
          );
          if(changeHandler){
            changeHandler(row.index,
            column.id,
            e.target.value,
            row.id,
            e.target.idTemp,
            row)
          }
          if(debouncedChangeHandler){
            debouncedChangedValue(row.index,
            column.id,
            e.target.value,
            row.id,
            e.target.idTemp,
            row)
          }
          if (toRecalculate) {
            enableRecalculateBtn(true);
          }
        }}
        onKeyPress={(e) => (noDecimal ? (e.key === "." && e.preventDefault()) : "")}

      ></input>
      {inputType === "percentage" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "38px",
            padding: "6px",
          }}
        >
          %{" "}
        </div>
      )}
    </div>
  );
}));

export default InputCell;
