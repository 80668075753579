import React from "react";
import "./ColorCodeMessage.css";

const ColorCodeMessage = (props) => {
  const { colorClass, msg } = props;
  return (
    <div className="mt-3 d-flex align-items-center">
      <div className={"indicate-div " + colorClass}></div>
      <span className="ml-2 font-italic mb-0">{msg}</span>
    </div>
  );
};

export default ColorCodeMessage;
