import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Calendar } from "primereact/calendar";
import { cloneDeep, isEmpty } from "lodash";
import moment from "moment";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import Select from "../../../../components/Filters/SingleSelect";
import * as storeConfigDetailsActions from "../../actions/StoreDetailsConfigActions/StoreConfigActions";
import * as StoreDetailsConsts from "./StoreDetailsDcConfigConstants";
import { getStoreGrades, resetAll, saveLeadTimeDcMapping } from "../../actions/DCConfigAction/DCConfigAction";
import "./Storedetailsconfig.css";
import {
  getStoreGradeColumnNamesByClient,
  getCapacityColumnsByClient,
  shouldHideForCurrentClient,
} from "../../../../utils/commonUtilities";
import MultiSelect from "../../../../components/Filters/MultiSelect";
import { handleSelectAll } from "../../../../utils/SelectAllHandler/SelectAllHander";

/**
 * Table Plugin
 */
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

/**
 * New Store Filetring Component
 * @param {*} props
 * @returns
 */
const EnterNewStoreSectionView = (props) => {
  let groupId = localStorage.getItem("group_id");

  const {
    storeConfigDetailsActionsProp,
    newStoreDetailsData,
    newStoreOptionsData,
    getDcDetailsByStore,
    storeGradeOptions,
    selectedNewStoreCode,
    selectedNewStore,
    newStoreOpeningDate,
    editModeDetails,
    editStoreCode,
    getStoreGrades,
    dcDetails,
  } = props;

  const [primaryDC, setPrimaryDC] = useState([]);
  const [secDC, setSecDC] = useState([])
  const [dcOptions, setDCOptions] = useState([]);
  const [updateStoreDetails, setUpdateStoreDetails] = useState(false);
  const [secondaryOptions, setSecDCOptions] = useState([]);
  const [isStoreChange, setIsStoreChange] = useState(false);

  useEffect(() => {
    let options = [];
    let map = {};
    dcDetails.forEach((item) => {
      options.push({
        label: item.dc_name,
        value: item.dc,
        leadTime: item.transit_time,
      });
      map[item.dc] = {name: item.dc_name, leadTime: item.transit_time};
    });
    setDCOptions(options);
    let storeDetails = cloneDeep(selectedNewStore[0]);
    let primary;
    if(storeDetails?.PDC?.length || storeDetails?.SDC?.length) {
      primary = storeDetails?.PDC?.split(",")?.map((dc) => {
        return { label: map[dc]?.name, value: dc, leadTime: map[dc]?.leadTime};
      });
      setSecDCOptions(getSecondaryDCOptions(primary, options));
    }
    // if (props?.editMode || updateStoreDetails) {
      let cards = []
      if (storeDetails?.PDC) {
        storeDetails.primary_dc = primary;
        setPrimaryDC(primary);
        cards = [...primary]
        delete storeDetails.PDC;
      }
      if (storeDetails?.SDC) {
        storeDetails.secondary_dc = storeDetails.SDC.split(",").map((dc) => {
          return { label: map[dc]?.name, value: dc, leadTime: map[dc]?.leadTime };
        });
        setSecDC(storeDetails.secondary_dc)
        cards = [...cards, ...storeDetails.secondary_dc]
        delete storeDetails.SDC;
      }
      (isStoreChange || props?.editMode) && storeConfigDetailsActionsProp.setDCCardDetails(cards);
      isStoreChange &&  props.saveLeadTimeDcMapping({data: {}})
      if(props?.editMode) {
        if(selectedNewStore?.[0]?.reservation_start_date) {
          storeDetails.reservation_start_date = selectedNewStore?.[0]?.reservation_start_date
        }
        if(selectedNewStore?.[0]?.open_date) {
          storeDetails.open_date = selectedNewStore?.[0]?.open_date
        }
      }
      storeConfigDetailsActionsProp.setSelectedNewStoreDetail([storeDetails]);
      setUpdateStoreDetails(false);
      setIsStoreChange(false)
    // }
  }, [dcDetails]);

  const newStoreTableColumns = React.useMemo(
    () => [
      {
        Header: StoreDetailsConsts.STORE_NAME_FIELD_LABEL,
        accessor: "store_name",
        width: 120,
      },
      getStoreGradeColumnNamesByClient(),
      {
        Header: StoreDetailsConsts.REGION_FIELD_LABEL,
        accessor: "region",
        width: 150,
      },
      getCapacityColumnsByClient(),
    ],
    []
  );

  useEffect(() => {
    if (editStoreCode && newStoreDetailsData.length) {
      onStoreNumberChange({ label: editStoreCode, value: editStoreCode });
    }
  }, [editStoreCode, newStoreDetailsData]);

  useEffect(() => {
    getStoreGrades();
    if (!isEmpty(editModeDetails) && editModeDetails?.isEditMode) {
      
      storeConfigDetailsActionsProp.setSelectedNewStoreCode({
        value: editModeDetails?.editModeNewStoreCode,
        label: editModeDetails?.editModeNewStoreCode,
      });
      const selectedStore = newStoreDetailsData.filter(
        (detail) => detail.store_code === editModeDetails?.editModeNewStoreCode
      );
      storeConfigDetailsActionsProp.setSelectedNewStoreDetail(selectedStore);
    }
  }, []);

  useEffect(() =>{
    if(isStoreChange) {
      props.resetAll()
      // setIsStoreChange(false)
    }
  }, [isStoreChange])

  const onStoreNumberChange = (storeCode, bool) => {
    bool && setIsStoreChange(true)
    // props.resetAll()
    storeConfigDetailsActionsProp.getDcSourceDetailsData({
      store_code: storeCode?.value,
    });
    storeConfigDetailsActionsProp.setSelectedNewStoreCode(storeCode);
    let selectedStore = newStoreDetailsData.filter(
      (detail) => detail.store_code === storeCode.value
    );
    let storeDetails = cloneDeep(selectedStore[0]);
    // if (!props?.editMode) {
    //   delete storeDetails.open_date;
    //   delete storeDetails.reservation_start_date;
    // }
    setUpdateStoreDetails(true);
    if (storeDetails.primary_dc) {
      storeDetails.PDC = storeDetails.primary_dc;
      delete storeDetails.primary_dc;
    }
    if (storeDetails.secondary_dc) {
      storeDetails.SDC = storeDetails.secondary_dc;
      delete storeDetails.secondary_dc;
    }
    if(props?.editMode) {
      if(selectedNewStore?.[0]?.reservation_start_date) {
        storeDetails.reservation_start_date = selectedNewStore?.[0]?.reservation_start_date
      }
      if(selectedNewStore?.[0]?.open_date) {
        storeDetails.open_date = selectedNewStore?.[0]?.open_date
      }
    }
    storeConfigDetailsActionsProp.setSelectedNewStoreDetail([storeDetails]);
  };

  const handleOpeningDateChange = (e) => {
    if (e) {
      const changedOpeningDate = moment(e.value).format("YYYY-MM-DD");
      storeConfigDetailsActionsProp.editOpeningDate(changedOpeningDate);
    } else {
      //No code here
    }
  };

  const updateNewStoreDetail = (key, value, event, that) => {
    let notDone = true;
    let selectedStore = cloneDeep(selectedNewStore);
    if (key === "primary_dc" || key === "secondary_dc") {
      props.saveLeadTimeDcMapping({data: {}})
      let cardDetails;
      let valuesArray = handleSelectAll(value, event, that);
      value = valuesArray[1];
      if (key === "primary_dc") {
        setPrimaryDC(value);
        setSecDC([])
        setSecDCOptions(getSecondaryDCOptions(value));
        storeConfigDetailsActionsProp.setSelectedNewStoreDetail([
          { ...selectedStore[0], [key]: value, secondary_dc: [] },
        ]);
        notDone = false;
        cardDetails = value
      }
      else {
        setSecDC(valuesArray[1])
        cardDetails = [...(primaryDC?.length ? primaryDC : selectedStore[0]?.primary_dc), ...value]
        // cardDetails = primaryDC?.length ? [primaryDC, ...value ] : []
      }
      let dcMap = {}
      dcDetails.forEach((item) => {
        dcMap[item.dc] = {name: item.dc_name, leadTime: item.transit_time};
      });
      cardDetails.forEach(dc => {
        dc.leadTime = dc.leadTime || dcMap[dc.value]?.leadTime
      })
      storeConfigDetailsActionsProp.setDCCardDetails([...new Map(cardDetails.map(item => [item.value, item])).values()]);
    }

    if(notDone) {
      storeConfigDetailsActionsProp.setSelectedNewStoreDetail([
        { ...selectedStore[0], [key]: value },
      ]);
    }
  };

  const getSecondaryDCOptions = (primary, options = dcOptions) => {
    primary = primary?.map((dc) => dc.value);
    return primary?.length
      ? options
          ?.filter((dc) => !primary?.includes(dc.value))
          .map((dc) => {
            return { label: dc.label, value: dc.value };
          })
      : [];
  };

  const getStoreGradeValue = (grade) => {
    if(grade?.value) {
      return grade
    }
    else {
      return grade ? { label: grade, value: grade } : ""
    }
  }

  const getNewStoreFields = () => {
    const arr = [
      <div className="column-1">
        <div className="p-field p-grid required">
          <label className="non-editable-labels p-col-fixed label-35">
            {StoreDetailsConsts.STORE_SEARCH_FIELD_LABEL}
          </label>
          <div className="p-col" style={{ marginTop: "1%" }}>
            <Select
              id="NEW_STORE_SELECTOR"
              name="storeNumber"
              value={
                selectedNewStoreCode?.value
                  ? selectedNewStoreCode
                  : editStoreCode
                  ? { label: editStoreCode, value: editStoreCode }
                  : ""
              }
              onChange={(e) => {onStoreNumberChange(e, true)}}
              placeholder="Select Store"
              options={newStoreOptionsData}
              className="p-col"
              isDisabled={editStoreCode}
            />
          </div>
        </div>
        <div className="p-field p-grid required">
          <label className="non-editable-labels p-col-fixed label-35">
            {StoreDetailsConsts.STORE_NAME_FIELD_LABEL}
          </label>
          <div className="p-col" style={{ marginTop: "1%" }}>
            <input
              disabled
              id="NEW_STORE_SELECTOR"
              // className="form-input form-control"
              className="p-col w-100 form-control"
              type={"text"}
              value={selectedNewStore[0]?.store_name}
              placeholder="Store Name"
            />
          </div>
        </div>
        {!shouldHideForCurrentClient("region") && (
          <div className="p-field p-grid required">
            <label className="non-editable-labels p-col-fixed label-35">
              {StoreDetailsConsts.REGION_FIELD_LABEL}
            </label>
            <div className="p-col" style={{ marginTop: "1%" }}>
              <input
                disabled
                id="NEW_STORE_SELECTOR"
                // className="form-input form-control"
                className="p-col w-100 form-control"
                type={"number"}
                value={selectedNewStore[0]?.region}
                placeholder="Region"
              />
            </div>
          </div>
        )}
        {/* <div className="p-field p-grid">
          <label className="non-editable-labels p-col-fixed label-35">
            {StoreDetailsConsts.CAPACITY_FIELD}
          </label>
          <div className="p-col" style={{ marginTop: "1%" }}>
            <input
              onChange={(e) => {
                updateNewStoreDetail("size", e?.target?.value);
              }}
              id="NEW_STORE_SELECTOR"
              // className="form-input form-control"
              className="p-col w-100 form-control"
              type={"number"}
              value={selectedNewStore[0]?.size}
              placeholder="Capacity"
              disabled={groupId != 1}
            />
          </div>
        </div> */}
      </div>,
      <div className="column-2">
        {!shouldHideForCurrentClient("country", "newStore") && (
          <div className="p-field p-grid">
            <label className="non-editable-labels p-col-fixed label-35">
              {StoreDetailsConsts.COUNTRY}
            </label>
            <div className="p-col" style={{ marginTop: "1%" }}>
              <input
                id="NEW_STORE_SELECTOR"
                // className="form-input form-control"
                className="p-col w-100 form-control"
                type={"text"}
                value={selectedNewStore[0]?.country}
                placeholder="Country"
                disabled
              />
            </div>
          </div>
        )}
        {!shouldHideForCurrentClient("channel", "newStore") && (
          <div className="p-field p-grid">
            <label className="non-editable-labels p-col-fixed label-35">
              {StoreDetailsConsts.CHANNEL}
            </label>
            <div className="p-col" style={{ marginTop: "1%" }}>
              <input
                id="NEW_STORE_SELECTOR"
                // className="form-input form-control"
                className="p-col w-100 form-control"
                type={"text"}
                value={selectedNewStore[0]?.channel}
                placeholder="Channel"
                disabled
              />
            </div>
          </div>
        )}
        {/* <div className="p-field p-grid">
          <label className="non-editable-labels p-col-fixed w-25">
            {StoreDetailsConsts.ZIP_FIELD}
          </label>
          <div className="p-col" style={{ marginTop: "1%" }}>
            <input
              id="NEW_STORE_SELECTOR"
              // className="form-input form-control"
              className="p-col w-100 form-control"
              type={"number"}
              placeholder="Zip Code"
              onChange={(e) => {
                updateNewStoreDetail(
                  "zipcode",
                  e?.target?.value >= 0 ? e?.target?.value : 0
                );
              }}
              value={selectedNewStore[0]?.zipcode}
              disabled={groupId != 1}
            />
          </div>
        </div> */}
        {!shouldHideForCurrentClient("address", "newStore") && (
          <div className="p-field p-grid">
            <label className="non-editable-labels p-col-fixed w-25">
              {StoreDetailsConsts.ADDRESS_FIELD}
            </label>
            <div className="p-col" style={{ marginTop: "1%" }}>
              <textarea
                rows={4}
                // id="NEW_STORE_SELECTOR"
                // className="form-input form-control"
                className="p-col w-100 text-area-input ml-2 p-2"
                // onChange={onStoreNumberChange}
                // value={selectedNewStoreCode}
                placeholder="Address"
                onChange={(e) => {
                  updateNewStoreDetail("address", e?.target?.value);
                }}
                value={selectedNewStore[0]?.address}
                disabled={groupId != 1}
              />
            </div>
          </div>
        )}
      </div>,
      <div className="column-3">
        {/* <div className="p-field p-grid">
          <label className="non-editable-labels p-col-fixed label-35">
            {StoreDetailsConsts.LATTITUDE_FIELD}
          </label>
          <div className="p-col" style={{ marginTop: "1%" }}>
            <input
              id="NEW_STORE_SELECTOR"
              className="p-col w-100 form-control"
              type={"number"}
              placeholder="Lattitude"
              onChange={(e) => {
                updateNewStoreDetail("lattitude", e?.target?.value);
              }}
              value={selectedNewStore[0]?.lattitude}
              disabled={groupId != 1}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="non-editable-labels p-col-fixed label-35">
            {StoreDetailsConsts.LONGITUDE_FIELD}
          </label>
          <div className="p-col" style={{ marginTop: "1%" }}>
            <input
              id="NEW_STORE_SELECTOR"
              className="p-col w-100 form-control"
              type={"number"}
              placeholder="Longitude"
              onChange={(e) => {
                updateNewStoreDetail("longitude", e?.target?.value);
              }}
              value={selectedNewStore[0]?.longitude}
              disabled={groupId != 1}
            />
          </div> 
        </div> */}
        <div className="p-field p-grid">
          <label className="non-editable-labels p-col-fixed label-35">
            {StoreDetailsConsts.STORE_GRADE_FIELD}
          </label>
          <div className="p-col" style={{ marginTop: "1%" }}>
            <Select
              id="NEW_STORE_SELECTOR"
              name="storeGrade"
              value={getStoreGradeValue(selectedNewStore[0]?.store_grade)}
              onChange={(val) => {
                updateNewStoreDetail("store_grade", val?.value);
              }}
              placeholder="Store Grade"
              options={storeGradeOptions}
              className="p-col"
              isDisabled={editStoreCode}
            />
            {/* <input
                            id="NEW_STORE_SELECTOR"
                            // className="form-input form-control"
                            className="p-col w-100 form-control"
                            type={"text"}
                            placeholder="Store Grade"
                            onChange={(e) => { updateNewStoreDetail("store_grade", e?.target?.value) }}
                            value={selectedNewStore[0]?.store_grade}
                            disabled={groupId != 1}
                        /> */}
          </div>
        </div>
        <div className="p-field p-grid required">
          <label className="non-editable-labels p-col-fixed label-35 dc_label">
            {StoreDetailsConsts.PRIMARY_DC_FIELD}
          </label>
          <div className={"p-col w-50 margin-top-1" + (editStoreCode ? " disable-dc-select": "")} style={{ marginTop: "1%" }}>
            <MultiSelect
              id="NEW_STORE_SELECTOR"
              name="primaryDC"
              dropdownValue={selectedNewStore[0]?.primary_dc}
              onDropDownChange={(val, event, that) => {
                updateNewStoreDetail("primary_dc", val, event, that);
              }}
              placeHolder="Primary DC"
              optionsArray={dcOptions}
              className="p-col"
              isDisabled={editStoreCode}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="non-editable-labels p-col-fixed label-35 dc_label">
            {StoreDetailsConsts.SECONDARY_DC_FIELD}
          </label>
          <div className={"p-col w-50 fill margin-top-1" + (editStoreCode ? " disable-dc-select": "")} style={{ marginTop: "1%" }}>
            <MultiSelect
              id="NEW_STORE_SELECTOR"
              name="primaryDC"
              dropdownValue={selectedNewStore[0]?.secondary_dc}
              onDropDownChange={(val, event, that) => {
                updateNewStoreDetail("secondary_dc", val, event, that);
              }}
              placeHolder="Secondary DC"
              optionsArray={secondaryOptions}
              className="p-col"
              isDisabled={editStoreCode}
            />
          </div>
        </div>
      </div>,
    ];
    return arr;
  };

  return (
    <>
      <h3 className="fnt-bold m-0 px-4 py-2">Store Attributes</h3>
      <hr />

      <div
        id="STORE_SEARCH_CONTAINER"
        className="store-search-container"
        style={{ overflow: "unset" }}
      >
        {getNewStoreFields().map((element) => element)}
        {/* <div className="p-field p-grid required">
                <label className="non-editable-labels p-col-fixed">
                    {StoreDetailsConsts.STORE_SEARCH_FIELD_LABEL}
                </label>
                <div className="p-col" style={{ marginTop: "1%" }}>
                    <Select
                        id="NEW_STORE_SELECTOR"
                        name="storeNumber"
                        value={selectedNewStoreCode}
                        onChange={onStoreNumberChange}
                        placeHolder={`Select df `}
                        options={newStoreOptionsData}
                        className="p-col"
                        isDisabled={editModeDetails?.isEditMode}
                    />
                </div>
            </div> */}
        {/* {selectedNewStoreCode && !isEmpty(selectedNewStoreCode.value) && !isEmpty(selectedNewStore) ?
                <>
                    <div className="p-field p-grid" style={{ marginTop: "2%" }}>
                        <label className="non-editable-labels p-col-fixed">
                            {StoreDetailsConsts.EXPECTED_OPENING_DATE_FIELD_LABEL}
                        </label>
                        <div className="p-col">
                            <Calendar
                                id="OPENING_DATE"
                                className="p-mb-2 opening-date-cal"
                                dateFormat="mm-dd-yy"
                                value={editModeDetails?.isEditMode && editModeDetails?.editModeOpeningDate ? new Date(editModeDetails.editModeOpeningDate) : new Date(newStoreOpeningDate)}
                                onChange={(e) => handleOpeningDateChange(e)}
                                readOnlyInput
                                showIcon
                            />
                        </div>
                    </div>
                    <ReactTableWithPlugins
                        shouldPagination={false}
                        data={selectedNewStore}
                        columns={newStoreTableColumns}
                        renderMarkup='TableMarkup'
                        tableId="newstore_enter_new"
                    />
                </>
                : null} */}
      </div>
    </>
  );
};

const mapStateToProps = ({ newStoreDetailConfig, sisterStoreDCConfig }) => ({
  newStoreDetailsData: newStoreDetailConfig.newStoreDetailsData,
  newStoreOptionsData: newStoreDetailConfig.newStoreOptionsData,
  selectedNewStoreCode: newStoreDetailConfig.selectedNewStoreCode,
  selectedNewStore: newStoreDetailConfig.selectedNewStore,
  newStoreOpeningDate: newStoreDetailConfig.newStoreOpeningDate,
  editModeDetails: newStoreDetailConfig?.editModeDetails,
  storeGradeOptions: sisterStoreDCConfig?.storeGrades,
  dcDetails: newStoreDetailConfig.dcSourceData,
  dcCardDetails: newStoreDetailConfig?.dcCardDetails
});

const mapDispatchToProps = (dispatch) => ({
  storeConfigDetailsActionsProp: bindActionCreators(
    storeConfigDetailsActions,
    dispatch
  ),
  getStoreGrades: () => dispatch(getStoreGrades()),
  saveLeadTimeDcMapping: (payload) => dispatch(saveLeadTimeDcMapping(payload)),
  resetAll: () => dispatch(resetAll())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnterNewStoreSectionView);
