import React, { useEffect, useState, useRef } from "react"
import { useHistory } from "react-router";
import { connect, useStore } from "react-redux";
import PageLoader from "../../../../components/Loader/PageLoader";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import EditIconCell from '../../../../components/Table/Cellrenderer/EditIconCell';
import NumberRangeColumnFilter from "../../../../components/Filters/NumberRangeColumnFilter";
import { getNewStoreList, resetReservedData } from "../../actions/DemandAction/DemandAction"
import { getNewReservedArticleData, deleteNewStore, resetDeleteData, getNewStoreAllocations } from "../../actions/DCConfigAction/DCConfigAction"
import { HEADER, RESERVED_HEADER, TABLE_HEADERS } from "./NewStoreConstants"
import { NEW_STORE_RESERVED_ALLOCATIONS, PRODUCT_STORE_STATUS } from "../../../../constants/routeConstants";
import { NEW_STORE_SETUP } from "../../../../constants/routeConstants";
import * as Notify from "../../../../components/Notification/Notifications";
import Notification from "../../../../components/Notification/Notifications";
import NewStoresTable from "./NewStoreListTable";
import { levelFiltersLabelFormatter } from "../../../../utils/filterLevelMapping";
import NewStoreAllocations from "./NewStoreAllocations";
import { resetStoreDetailsConfig } from "../../actions/StoreDetailsConfigActions/StoreConfigActions";


const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const NewStoreList = (props) => {
    // const reserveRef = useRef()
    const groupId = localStorage.getItem("group_id")
    const history = useHistory()
    const { getNewStoreList, resetReservedData, data, dataLoading, dataError, articleLevelData, articleLevelDataLoading, deleteNewStore,
        articleLevelDataError, getNewReservedArticleData, deleteNewStoreLoading, deleteNewStoreError, deleteNewStoreSuccess,
        resetDeleteData, getNewStoreAllocations, newStoreAllocations, newStoreAllocationsLoading, newStoreAllocationsError } = props

    const [showReservedInv, setShowReserve] = useState(false)
    const [reserveData, setReserveData] = useState([])
    const [showAllocations, setShowAllocations] = useState(false)
    const [selectedStore, setSelectedStore] = useState()
    const [isDetailedView, setIsDetailedView] = useState(false)

    const getColumnBehaviour = (isApproved, value) => {
        return value
        // return <div className="row min-100">
        //     <span className={"min-100 pt-2 " + (isApproved ? "sister-store__map" : "")}>
        //         {value}
        //     </span>
        // </div>
    }

    const columns = [
        {
            sticky: "left",
            Header: TABLE_HEADERS.STORES,
            accessor: 'store_code',
            Cell: (instance) => getColumnBehaviour(instance?.row?.original?.approved, instance.value),
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: TABLE_HEADERS.STORE_NAME,
            accessor: 'store_name',
            Cell: (instance) => getColumnBehaviour(instance?.row?.original?.approved, instance.value),
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: TABLE_HEADERS.START_DATE,
            accessor: 'reservation_start_date',
            Cell: (instance) => getColumnBehaviour(instance?.row?.original?.approved, instance.value),
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: TABLE_HEADERS.OPENING_DATE,
            accessor: 'open_date',
            Cell: (instance) => getColumnBehaviour(instance?.row?.original?.approved, instance.value),
            Filter: ColumnFilter,
            width: 200
        },
        // {
        //     Header: TABLE_HEADERS.LEAD_TIME,
        //     accessor: 'lead_time',
        //     Filter: ColumnFilter
        // },
        {
            Header: TABLE_HEADERS.RESERVED_QTY,
            accessor: 'reserve_quantity',
            Cell: (instance) => getColumnBehaviour(instance?.row?.original?.approved, instance.value),
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: TABLE_HEADERS.APPROVED_QTY,
            accessor: 'approved_quantity',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: TABLE_HEADERS.ALLOCATED_QTY,
            accessor: 'allocated_quantity',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: "",
            accessor: "approve-btn",
            Cell: (instance) => (
                <div>
                    <button
                        className="btn btn-outline-secondary btn-sm px-2 mr-4 rounded-lg text-body"
                        onClick={() => { goToApproveReleaseFlow("new_store_approval", instance?.row?.original) }}
                    >
                        Go to Approve
                        <i
                            className="fa fa-angle-right ml-2"
                            title="Configure"
                            aria-hidden="true"
                        ></i>
                    </button>
                    <button
                        disabled={!instance?.row?.original?.allocated_quantity}
                        className="btn btn-outline-secondary btn-sm px-2 mr-4 rounded-lg text-body"
                        onClick={() => { goToApproveReleaseFlow("new_store_release", instance?.row?.original) }}
                    >
                        Go to Release
                        <i
                            className="fa fa-angle-right ml-2"
                            title="Configure"
                            aria-hidden="true"
                        ></i>
                    </button>
                    <button
                        disabled={!instance?.row?.original?.is_released}
                        className="btn btn-outline-secondary btn-sm px-2 rounded-lg text-body"
                        onClick={() => { goToApproveReleaseFlow("", instance?.row?.original) }}
                    >
                        View Allocations
                        <i
                            className="fa fa-angle-right ml-2"
                            title="Configure"
                            aria-hidden="true"
                        ></i>
                    </button>
                </div>
            ),
            disableFilters: true,
            resizable: true,
            width: 350,
        },
        {
            Header: "Actions",
            accessor: "actions",
            sticky: "right",
            Cell: (instance) => (
                <div>
                    <EditIconCell
                        handleEdit={handleEdit}
                        handleDelete={groupId == 1 && !instance?.row?.original?.approved ? handleDelete : false}
                        instance={instance}
                    ></EditIconCell>
                </div>
            ),
            disableFilters: true,
            resizable: true,
            width: 100
        }
    ]

    const articleTableColumns = [
        {
            sticky: "left",
            Header: TABLE_HEADERS.STYLE_COLOR,
            accessor: 'article',
            Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
            filter: 'bulkFilterCommaSeperated',
            width: 200
        },
        {
            sticky: "left",
            Header: TABLE_HEADERS.STYLE_DESC,
            accessor: 'style_name',
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: TABLE_HEADERS.STORE_IDS,
            accessor: 'store_ids',
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: TABLE_HEADERS.DESC,
            accessor: 'store_name',
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: levelFiltersLabelFormatter("level1"),
            accessor: 'l1_name',
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: 'l2_name',
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: levelFiltersLabelFormatter("level3"),
            accessor: 'l3_name',
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: TABLE_HEADERS.RESERVED_QTY,
            accessor: 'reserve_quantity',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: TABLE_HEADERS.APPROVED_QTY,
            accessor: 'approved_quantity',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: TABLE_HEADERS.ALLOCATED_QTY,
            accessor: 'allocated_quantity',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        }
    ]

    const goToApproveReleaseFlow = (type, store) => {
        if (type) {
            history.push(`${NEW_STORE_RESERVED_ALLOCATIONS}`, { type, store })
        }
        else {
            setSelectedStore(store?.store_code)
            setShowAllocations(true)
            getNewStoreAllocations({ store_code: store?.store_code })
        }
    }

    useEffect(() => {
        setShowReserve(false)
        getNewStoreList({})
        getNewReservedArticleData()
    }, [])

    useEffect(() => {
        return () => {
            resetReservedData()
        }
    }, [])

    const handleEdit = (instance) => {
        // let storeDetails = {
        //     allocation_id: instance.allocation_id,
        //     allocation_name: instance.allocation_name,
        //     store_code: instance.store_id,
        //     sister_store_code: instance.sister_store_id,
        //     opening_date: instance.opening_date,
        //     is_edit: true
        // }
        history.push(`${NEW_STORE_SETUP}`, { ...instance, is_edit: true })
    }

    const handleDelete = (instance) => {
        if (window.confirm("Are you sure you wish to delete?")) {
            deleteNewStore({ store_code: instance?.store_code })
        }
    }

    const release = (instance) => {
        let storeDetails = {
            isRelease: true,
            allocation_id: instance.allocation_id,
            sister_store_code: instance.sister_store_id,

        }
        history.push(`${PRODUCT_STORE_STATUS}`, { storeDetails })
    }

    // const getStoresData = (store) => {
    //     setShowReserve(true)
    //     setTimeout(() => {
    //         reserveRef?.current?.scrollIntoView()
    //     }, 100)
    //     getNewStoreList({
    //         "level1": [
    //             "Apparel"
    //         ],
    //         "level2": [
    //             "Infant"
    //         ],
    //         "storesFilter": "Allocation Required - New In DC",
    //         "dc": [
    //             9
    //         ],
    //         "new_store": true
    //     })
    // }

    const goToCreateNewStore = () => {
        props.resetStoreDetailsConfig()
        history.push(`${NEW_STORE_SETUP}`);
    }

    useEffect(() => {
        if (deleteNewStoreSuccess) {
            Notify.success("New Store Deleted Successfully!!")
            getNewStoreList({})
            getNewReservedArticleData()
            resetDeleteData()
        }
        else if (deleteNewStoreError) {
            Notify.error("Error in deleting new store!!")
        }
    }, [deleteNewStoreError, deleteNewStoreSuccess])

    const detailedView = (status) => {
        setIsDetailedView(status)
    }

    return (
        <>
            {
                !isDetailedView &&
                <>
                    {/* <Notification /> */}
                    <div className="container__wrapper">
                        <div className="container__header m-0 p-3 align-items-end">
                            <h3 className="fnt-bold m-0">{HEADER}</h3>
                            <div className="row">
                                {/* <button className="btn btn-primary-light mr-4" title="Edit">Edit</button> */}
                                <button disabled={groupId != 1} className="btn btn-primary mr-2 px-4" onClick={goToCreateNewStore}>Add New Store</button>
                            </div>
                        </div>
                        <hr className="m-0" />
                        <NewStoresTable data={data} dataLoading={dataLoading} columns={columns}
                            deleteNewStoreLoading={deleteNewStoreLoading} />

                        {/* <div className="mt-3 d-flex align-items-center">
                    <div className="indicate-div sister-store__map"></div>
                    <span className="ml-2 font-italic mb-0">Approved Stores</span>
                </div> */}

                    </div>
                </>
            }
            {
                showAllocations &&
                <NewStoreAllocations data={newStoreAllocations} loading={newStoreAllocationsLoading}
                    error={newStoreAllocationsError} store={selectedStore} setDetailedView={detailedView} isDetailedView={isDetailedView} />
            }
            {
                // showReservedInv &&
                !isDetailedView &&
                <div className="container__wrapper">
                    <h3 className="fnt-bold m-0 p-3">{RESERVED_HEADER}</h3>
                    <hr className="m-0" />
                    <section className="section mt-4 m-0">
                        <PageLoader loader={articleLevelDataLoading} gridLoader={true}>
                            {(articleLevelDataError) ? (<div className="error">{articleLevelDataError}</div>) :
                                (data ? (
                                    <ReactTableWithPlugins
                                        shouldPagination
                                        data={(articleLevelData ? articleLevelData : [])}
                                        columns={articleTableColumns}
                                        renderMarkup='TableMarkup'
                                        keyRT="sortAndSearch"
                                        tableId="newstore_reserved"
                                        tableWrapperStyle={{
                                            height: "fit-content",
                                            maxHeight: "50rem",
                                        }}
                                        headerWrapperStyle={{ position: "sticky", top: 0, zIndex: "4" }}
                                    />
                                ) : null)}
                        </PageLoader>
                    </section>
                </div>
            }
        </>
    )
}

const mapStateToProps = ({ demandConstraints, sisterStoreDCConfig }) => ({
    data: demandConstraints.newStoreData,
    dataLoading: demandConstraints.newStoreDataLoading,
    dataError: demandConstraints.newStoreDataError,
    articleLevelData: sisterStoreDCConfig.articleReservedData,
    articleLevelDataLoading: sisterStoreDCConfig.articleReservedDataLoading,
    articleLevelDataError: sisterStoreDCConfig.articleReservedDataError,
    deleteNewStoreLoading: sisterStoreDCConfig?.deleteNewStoreLoading,
    deleteNewStoreSuccess: sisterStoreDCConfig?.deleteNewStoreSuccess,
    deleteNewStoreError: sisterStoreDCConfig?.deleteNewStoreError,
    newStoreAllocations: sisterStoreDCConfig?.newStoreAllocations,
    newStoreAllocationsLoading: sisterStoreDCConfig?.newStoreAllocationsLoading,
    newStoreAllocationsError: sisterStoreDCConfig?.newStoreAllocationsError
});

const mapDispatchToProps = (dispatch) => ({
    resetReservedData: () => dispatch(resetReservedData()),
    getNewStoreList: (payload) => dispatch(getNewStoreList(payload)),
    getNewReservedArticleData: () => dispatch(getNewReservedArticleData()),
    deleteNewStore: (payload) => dispatch(deleteNewStore(payload)),
    getNewStoreAllocations: (payload) => dispatch(getNewStoreAllocations(payload)),
    resetDeleteData: () => dispatch(resetDeleteData()),
    resetStoreDetailsConfig: () => dispatch(resetStoreDetailsConfig())
});

export default connect(mapStateToProps, mapDispatchToProps)(NewStoreList);