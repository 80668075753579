import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import NumberRangeColumnFilter from "../../../../components/Filters/NumberRangeColumnFilter";
import SetAllModal from "../../../../components/ErrorModal/SetAllModal";
import { DEMAND_TABLE_HEADERS } from "./DemandConstants"
import * as Notify from "../../../../components/Notification/Notifications";
import { cloneDeep, isEmpty } from "lodash";
import { getSizeCurve } from "../../actions/DemandAction/DemandAction"
import ProductProfileDetailsTable from "./ProductProfileDetailTable";
import { levelFiltersLabelFormatter } from "../../../../utils/filterLevelMapping";
import SelectCell from "../../../../components/Table/Cellrenderer/SelectCell";
import { numberUSFormatting, addPer, twoDecimal } from '../../../../utils/formatters/valueFormatters';
import NumberRangeColumnFilterServerSide from "../../../../components/Filters/NumberRangeColumnFilterServerSide";
import ColumnFilterServerSide from "../../../../components/Filters/ColumnFilterServerSide";
import ReactTollTip from "../../../../components/Table/Cellrenderer/ReactToolTip";
import PropTypes from 'prop-types';

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const DemandConstraintsTable = (props) => {
    const ppTableRef = useRef()
    const { data, demandSelectedRows, setSelectedRows, isAddProduct, store, isFinalTable, addProSelectedRows, editMode, ppData, 
        ppcolumn, ppLoading, ppError, fetchStoresData, initSelectedRows, sisterStores, getSizeCurve, fetchData, fecthDataWithSorting,
        updateSetAll, updateCheckedRows, paginateTable, initialPageIndex, fecthDataWithSearchTerm, searchTermReq,
        existingProducts } = props


    const [tableData, setTableData] = useState([])
    const [demadTableInstance, setDemandTableInstance] = useState({})
    const [initialSelectedRows, setInitialSelectedRows] = useState({})
    const [showSetAll, setShowSetAll] = useState(false)
    const [minSA, setMinSA] = useState()
    const [maxSA, setMaxSA] = useState()
    const [apsSA, setAPSSA] = useState()
    const [wosSA, setWOSSA] = useState()
    const [actualROSSA, setActualROSSA] = useState()
    const [demandSA, setDemandSA] = useState()
    const [showPPTable, setShowPPTable] = useState(false)
    const [selectedPPName, setPPName] = useState(null)
    const [columnConfig, setColumnConfig] = useState([])
    const [selectedPP, setSelectedPP] = useState()
    const [ppSisterStoreData, setPPSisterStoreData] = useState([])
    const [hideCheckbox, setHideCheckbox] = useState(false)
    const [sizeColumns, setSizeColumns] = useState([])
    const [sizeCurveData, setSizeCurveData] = useState([])
    const [disableMovebutton, setDisableMoveButton] = useState(false)
    const [hideTotalRecords, setHideTotalRecords] = useState(true)
    const [hideTotalPages, setHideTotalPages] = useState(true)

    const setAllValues = [{ varName: minSA, key: "min_stock", setFunction: setMinSA },
    { varName: maxSA, key: "max_stock", setFunction: setMaxSA },
    { varName: apsSA, key: "aps", setFunction: setAPSSA, type: "float" },
    { varName: wosSA, key: "wos", setFunction: setWOSSA, type: "int" }]

    useEffect(() => {
        if(props.out_of_data) {
          setHideTotalPages(false);
          setHideTotalRecords(false);
        }
        else{
          setHideTotalPages(true);
          setHideTotalRecords(true);
        }
      },[props.out_of_data])

    const demandConstraintsColumns = [
        {
            sticky: "left",
            Header: DEMAND_TABLE_HEADERS.ARTICLE,
            accessor: 'article',
            filter: 'bulkFilterCommaSeperated',
            Filter: (instance) => !paginateTable ? <ColumnFilter {...instance} placeholder="Search in bulk by ','" /> :
                <ColumnFilterServerSide placeholder={"Search in bulk by ','"}
                {...instance} searchTermValue={searchTermReq?.article?.search} 
                changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "array", ",")} />
        },
        {
            Header: DEMAND_TABLE_HEADERS.STYLE_NAME,
            accessor: 'color_desc',
            Filter: (instance) => !paginateTable ? <ColumnFilter {...instance} /> :
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color_desc?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
            width: 300
        },
        {
            Header: DEMAND_TABLE_HEADERS.DESC,
            accessor: 'style_name',
            Filter: (instance) => !paginateTable ? <ColumnFilter {...instance} /> :
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
            width: 300
        },
        {
            Header: DEMAND_TABLE_HEADERS.GLOBAL_COLOR,
            accessor: 'color',
            Filter: (instance) => !paginateTable ? <ColumnFilter {...instance} /> :
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        {
            Header: DEMAND_TABLE_HEADERS.ASSORT,
            accessor: "assortment_indicator",
            width: 250,
            Filter: (instance) => !paginateTable ? <ColumnFilter {...instance} /> :
              <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.assortment_indicator?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
          },
        {
            Header: DEMAND_TABLE_HEADERS.STORE_GROUP,
            accessor: "store_groups",
            Cell: (instance) => <ReactTollTip {...instance}/>,
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: levelFiltersLabelFormatter("level1"),
            accessor: "l1_name",
            Filter: (instance) => !paginateTable ? <ColumnFilter {...instance} /> :
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l1_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: "l2_name",
            Filter: (instance) => !paginateTable ? <ColumnFilter {...instance} /> :
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l2_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        {
            Header: levelFiltersLabelFormatter("level3"),
            accessor: "l3_name",
            Filter: (instance) => !paginateTable ? <ColumnFilter {...instance} /> :
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l3_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
            width: 200
        },
        {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: "l4_name",
            Filter: (instance) => !paginateTable ? <ColumnFilter {...instance} /> :
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l4_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
            width: 200
        },
        {
            Header: levelFiltersLabelFormatter("level5"),
            accessor: "l5_name",
            Filter: (instance) => !paginateTable ? <ColumnFilter {...instance} /> :
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l5_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
            width: 200
        },
        {
            Header: levelFiltersLabelFormatter("level6"),
            accessor: "l6_name",
            Filter: (instance) => !paginateTable ? <ColumnFilter {...instance} /> :
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l6_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
            width: 200
        },
        {
            Header: DEMAND_TABLE_HEADERS.PRODUCT_TYPE,
            accessor: 'product_type',
            disableSortBy: true,
            Filter: (instance) => !paginateTable ? <ColumnFilter {...instance} /> :
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.product_type?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        // {
        //     Header: DEMAND_TABLE_HEADERS.SISTER_STORE,
        //     accessor: 'sister_store-default',
        //     Cell: (instance) => <SelectCell {...instance}
        //         dropdownOptions={ [{label: "APS", value: "APS"}, {label: "Fixed", value: "Fixed"}, {label: "IA Forecast", value: "IA"}]}
        //     />,
        //     disableFilters: true,
        // },
        {
            Header: DEMAND_TABLE_HEADERS.MIN,
            accessor: 'min_stock',
            disableSortBy: true,
            Filter: (instance) => !paginateTable ? <NumberRangeColumnFilter {...instance} /> :
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.min_stock?.value?.min} maxSearchTermValue={searchTermReq?.min_stock?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />,
            filter: 'between',
        },
        {
            Header: DEMAND_TABLE_HEADERS.MAX,
            accessor: 'max_stock',
            disableSortBy: true,
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: (instance) => !paginateTable ? <NumberRangeColumnFilter {...instance} /> :
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.max_stock?.value?.min} maxSearchTermValue={searchTermReq?.max_stock?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />,
            filter: 'between'
        },
        {
            Header: DEMAND_TABLE_HEADERS.WOS,
            accessor: row => Number(row.wos),
            id: "wos",
            disableSortBy: true,
            Filter: (instance) => !paginateTable ? <NumberRangeColumnFilter {...instance} /> :
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.wos?.value?.min} maxSearchTermValue={searchTermReq?.wos?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />,
            filter: 'between',
        },
        {
            Header: DEMAND_TABLE_HEADERS.APS,
            accessor: 'aps',
            disableSortBy: true,
            Filter: (instance) => !paginateTable ? <NumberRangeColumnFilter {...instance} /> :
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.aps?.value?.min} maxSearchTermValue={searchTermReq?.aps?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />,
            filter: 'between',

        },
        // {
        //     Header: DEMAND_TABLE_HEADERS.ACTUAL_ROS,
        //     accessor: 'ros',
        //     Filter: (instance) => NumberRangeColumnFilter,
        //     Filter: (instance) => 'between'
        // },
        // {
        //     Header: DEMAND_TABLE_HEADERS.INV,
        //     accessor: 'inv_available',
        //     Filter: (instance) => NumberRangeColumnFilter,
        //     Filter: (instance) => 'between'
        //     // width: 180
        // },
        {
            Header: DEMAND_TABLE_HEADERS.DEMAND_ESTIMATED,
            accessor: 'demand_estimated',
            disableSortBy: true,
            Filter: (instance) => !paginateTable ? <NumberRangeColumnFilter {...instance} /> :
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.demand_estimated?.value?.min} maxSearchTermValue={searchTermReq?.demand_estimated?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />,
            filter: 'between',
        },
        {
            Header: DEMAND_TABLE_HEADERS.PRODUCT_PROFILE,
            disableSortBy: true,
            accessor: "product_profile",
            Cell: (instance) => (
                !(instance?.row?.original?.is_product_profile_available) ?
                    <div className="clickable grey_out_column" onClick={() => getContribution(instance.row?.original)}>
                        {instance.row?.original?.product_profile?.label}
                    </div>
                    :
                    <div>
                        <div className="clickable" onClick={() => getContribution(instance.row?.original)}>{instance.row?.original?.product_profile?.label}</div>
                    </div>
            ),
            disableFilters: true,
            // Filter: (instance) => !paginateTable ? <ColumnFilter {...instance} /> :
            //     <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.product_profile?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
            // sticky: "right",
            width: 200
        }
    ]

    const prepFilter = [
        // {
        //     id: 1,
        //     isRequired: false,
        //     label: "Min",
        //     name: "min",
        //     className: "setAll__input__center_3",
        //     value: minSA,
        //     onChange: (e) => {
        //         let val = e.target.value
        //         if (val < 0) {
        //             val = 0
        //         }
        //         setMinSA(val)
        //     },

        //     input: true,
        //     type: "number",
        //     min: 0
        // },
        // {
        //     id: 2,
        //     isRequired: false,
        //     label: "Max",
        //     name: "max",
        //     className: "setAll__input__center_3 position-relative",
        //     value: maxSA,
        //     errorDiv: Number(maxSA) && Number(minSA) && Number(minSA) >= Number(maxSA) ? <div className="errorClass value-error">Max should be greater than Min</div> : false,
        //     onChange: (e) => {
        //         let val = e.target.value
        //         if (val < 0) {
        //             val = 0
        //         }
        //         setMaxSA(val)
        //     },
        //     input: true,
        //     type: "number",
        //     min: 0
        // },
        {
            id: 3,
            isRequired: false,
            label: "WOS",
            name: "wos",
            className: "setAll__input__center_3",
            value: wosSA,
            onChange: (e) => {
                let val = e.target.value
                if (val < 0) {
                    val = 0
                }
                setWOSSA(val)
            },
            input: true,
            type: "number",
            min: 0,
            onKeyDown: (event) => {
                if(event.key==='.'){event.preventDefault()}        
            }
        },
        {
            id: 4,
            isRequired: false,
            label: "APS",
            name: "aps",
            className: "setAll__input__center_3",
            value: apsSA,
            onChange: (e) => {
                let val = e.target.value
                if (val < 0) {
                    val = 0
                }
                setAPSSA(val)
            },
            input: true,
            type: "number",
            min: 0
        }
    ]

    const getContribution = (pp) => {
        setSizeCurveData([])
        setSelectedPP(pp)
        setPPName(pp?.product_profile?.label)
        setShowPPTable(true)
        setTimeout(() => {
            ppTableRef?.current?.scrollIntoView()
        }, 200)
        getSizeCurve({ sister_stores: pp?.sister_stores, product_profile_code: `${pp?.product_profile?.value}` })
    }

    useEffect(() => {
        setInitialSelectedRows({})
        setShowPPTable(false)
        let selectedRows = {}
        if(demandSelectedRows?.length) {
            demandSelectedRows?.map((item, index) => {
                selectedRows[index] = true
            })
            setInitialSelectedRows(selectedRows)
        }
        else if(!isEmpty(demandSelectedRows)) {
            setInitialSelectedRows(demandSelectedRows)
        }
        let demandData = cloneDeep(data)
        demandData.map(item => {
            let est = Number(item.demand_estimated)
            let min = Number(item.min_stock)
            let max = Number(item.max_stock)
            est = est < min ? min : (est > max ? max : est)
            item.demand_estimated = est > max ? max : est.toFixed(0)
            if (isFinalTable || isAddProduct) {
                item.showCheckbox = item.approved || existingProducts?.includes(item.article);
                item.checkColor = item.approved || existingProducts?.includes(item.article);
            }
        })
        setHideCheckbox(demandData.length === demandData.filter(item => item.approved).length)
        setTableData(demandData)
    }, [data])

    useEffect(() => {
        if (isAddProduct) {
            let selectStoreHeader = {
                Header: DEMAND_TABLE_HEADERS.SELECT_STORE,
                // Cell: (instance) => <SelectCell {...instance} dropdownOptions={sisterStores} />,
                // Filter: (instance) => 'between',
                accessor: "sister_store_ref",
                disableFilters: true,
                width: 250
            }
            demandConstraintsColumns.splice(demandConstraintsColumns.length - 1, 0, selectStoreHeader)
        }
        // setColumnConfig(demandConstraintsColumns)
    }, [])

    const setTableRows = (rows) => {
        let selectedRows = {}
        rows?.map((item, index) => {
            selectedRows[index] = true
        })
        setInitialSelectedRows(selectedRows)
    }

    const handleSetAllOk = () => {
        setTableInstance(demadTableInstance)
        const setAllRows = Object.keys(demadTableInstance.state.selectedRowIds)
        showSetAll && setShowSetAll(false)

        setTableData((oldData) => {
            const newData = cloneDeep(oldData);
            setAllRows.forEach(setAllRow => {
                if(!newData[setAllRow]?.approved){
                    setAllValues.forEach(item => {
                        if (item.varName || item.varName === 0) {
                            newData[setAllRow][item.key] = item.type === "float" ? parseFloat(item.varName) : parseInt(item.varName)
                        }
                    })

                    // let wos = parseInt(wosSA) >= 0 ? parseInt(wosSA) : 0
                    // let aps = parseInt(apsSA) >= 0 ? parseInt(apsSA) : 0
                    let est = newData[setAllRow].wos * newData[setAllRow].aps;
                    est = est < newData[setAllRow].min_stock ? newData[setAllRow].min_stock : (est > newData[setAllRow].max_stock ? newData[setAllRow].max_stock : est)
                    newData[setAllRow]["demand_estimated"] = est
                }
            })
            if (props?.setPreSelectedProducts) {
                props.setPreSelectedProducts(newData, true)
            }
            return newData;
        });

    }

    const handleSetAllCancel = () => {
        setShowSetAll(false);
    }

    const setAllHandler = () => {
        if (Object.keys(demadTableInstance.state.selectedRowIds).length > 0) {
            setAllValues.map(item => {
                item.setFunction(null)
            })
            setShowSetAll(true);

            // demadTableInstance.data.map(item => {
            //     if (selectedIds.includes(item.article)) {
            //         selectedRows.push(item)
            //     }
            //     else {
            //         remainingRows.push(item)
            //     }
            // })
            setTableRows(demadTableInstance.getSelectedRows)
            // setTableData([...selectedRows, ...remainingRows])

        }
        else {
            Notify.error(`Please select atleast 1 product to use Set All functionality!!`)
        }
    }

    const setTableInstance = (instance) => {
        if (isAddProduct) {
            props.setRTInstance(instance)
        }
        else {
            paginateTable && props.setRTInstance(instance)
            setSelectedRows(instance.data, instance.getSelectedRows)
            setDemandTableInstance(instance)
            setDisableMoveButton(!instance?.getSelectedRows?.length)
        }
    }

    useEffect(() => {
        if (ppData.length) {
            let data = cloneDeep(ppData)
            data[0].size_curve.store_code = selectedPP?.sister_stores?.[0]
            let userCreatedProfileTabsColumns = []
            userCreatedProfileTabsColumns.push({
                Header: 'Store Number',
                accessor: 'store_code',
                Filter: (instance) => (instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
                Filter: (instance) => 'bulkFilterSpaceSeperated',
                Cell: ({ value, row }) =>
                    <div className="row min-100">
                        <span className={"min-100 pt-2 " + (row?.original?.impute_store_tag ? "sister-store__map" : "")}>
                            {value}
                        </span>
                    </div>
            })
            data[0]?.sizeArr?.forEach(size => {
                userCreatedProfileTabsColumns.push({
                    Header: size,
                    accessor: (data) => addPer(twoDecimal(data[size])),
                    Filter: (instance) => ColumnFilter,
                    Cell: ({ value, row }) =>
                        <div className="row min-100">
                            <span className={"min-100 pt-2 " + (row?.original?.impute_store_tag ? "sister-store__map" : "")}>
                                {value}
                            </span>
                        </div>
                })
            })
            setSizeCurveData([data[0].size_curve])
            setSizeColumns(userCreatedProfileTabsColumns)
        }
    }, [ppData])

    useEffect(() => {
        if (!isEmpty(addProSelectedRows)) {
            setInitialSelectedRows(addProSelectedRows)
        }
    }, [addProSelectedRows])

    const moveProducts = () => {
        const selectedRows = demadTableInstance?.selectedFlatRows?.map(row => row?.original).filter(item => !item?.approved)
        props.removeProducts(selectedRows);
    }

    return (
        // <>
        <div>
            <ReactTableWithPlugins
                shouldPagination
                data={(tableData ? tableData : [])}
                columns={demandConstraintsColumns}
                initialSelectedRows={initialSelectedRows}
                renderMarkup='TableMarkup'
                keyRT="StrategyPageTable"
                features={hideCheckbox ? [] : ["CHECKBOX_FOR_FIRSTCOLUMN"]}
                getInstanceOnMount={(instance) => {
                    setTableInstance(instance)
                }}
                getManualSortByOptions={(instance) => {
                    fecthDataWithSorting(instance)
                }}
                manualSortBy={paginateTable}
                fetchData={(data) => paginateTable && fetchData(data)}
                tableId={props?.tableId ? props.tableId : "newstore_demand"}
                checkAllCallBack={(val) => paginateTable && updateSetAll(val)}
                checkCallBack={(val) => paginateTable && updateCheckedRows(val)}
                initialPageIndex={paginateTable && initialPageIndex}
                hideTotalRecords = {paginateTable && hideTotalRecords}
                hideTotalPages = {paginateTable && hideTotalPages}
                hideDropdown={!!paginateTable}
                out_of_data={props.out_of_data}
                tableWrapperStyle={{height:'fit-content', maxHeight:'50rem'}}
                headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
            />
            {!!tableData?.length && !isAddProduct && !props.setMoveProducts && editMode &&
                <div className="d-flex align-items-center" style={{ marginTop: "15px" }}>
                    <div className="indicate-div style_indicate_color"></div>
                    <span className="ml-2 font-italic mb-0">Style color already approved.</span>
                </div>}
            {isAddProduct &&
            <div className="d-flex align-items-center demand_mt15">
                <div className="indicate-div style_indicate_color"></div>
                <span className="ml-2 font-italic mb-0"> Style color already available in the main table.</span>
            </div>
            }
            <div className="d-flex align-items-center" style={{ marginTop: "15px" }}>
                <div className="indicate-div sister-store__map"></div>
                <span className="ml-2 font-italic mb-0"> No Size Curve available for the style color in the sister store.</span>
            </div>
            {
                !isAddProduct &&
                <div className="row justify-content-center">
                    {
                        !props.setMoveProducts &&
                        <button
                            className="btn btn-primary mr-4"
                            onClick={setAllHandler}
                        >
                            Set All
                        </button>
                    }
                    {
                        props.setMoveProducts &&
                        <button className="btn btn-primary px-2 mr-4" onClick={props.setMoveProducts} disabled={disableMovebutton}>
                            Move to Selected Styles
                        </button>
                    }
                    {
                        !props.setMoveProducts &&  
                        <button disabled={!demadTableInstance?.selectedFlatRows?.length} className="btn btn-primary px-2 mr-4" onClick={moveProducts}>
                            Remove Styles
                        </button>
                    }
                </div>
            }
            {
                showSetAll &&
                <SetAllModal filter={prepFilter} disableApply={(maxSA || minSA) && Number(maxSA) <= Number(minSA)}
                    handleOk={handleSetAllOk} handleCancel={handleSetAllCancel}
                    handleClose={handleSetAllCancel}
                />
            }
            {
                showPPTable &&
                <>
                    <span ref={ppTableRef}></span>
                    <ProductProfileDetailsTable data={sizeCurveData || []} column={sizeColumns} loading={ppLoading}
                        store={store} ppName={selectedPPName} error={ppError} initialHiddenColumns={["overall"]}
                    />
                </>
            }
        </div>

        // </>
    )
}

const mapStateToProps = ({ demandConstraints }) => {
    return {
        ppLoading: demandConstraints.sizeCurveLoading,
        ppData: demandConstraints.sizeCurveData,
        ppError: demandConstraints.sizeCurveError,
    }
}

const mapDispatchToProps = (dispatch) => ({
    getSizeCurve: (payload) => dispatch(getSizeCurve(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DemandConstraintsTable)

DemandConstraintsTable.propTypes = {
    removeProducts: PropTypes.func
};