import React, { useEffect, useState } from "react";
import Styles from "./TableMarkupStyles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ColumnSelection from "../../ColumnSelection/ColumnSelection";
import { getHideColumns, saveColumnOrderApi } from "../../../utils/commonUtilities";
// import './ReactTable.css';
const TableMarkup = (props) => {
  const [currentColumnOrder, setCurrentColumnOrder] = useState([])
  const [saveColOrderLoading, setLoader] = useState(false)
  let {
    state: { sortBy },
    getManualSortByOptions,
    manualSortBy,
    allColumns,
    setColumnOrder,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    rows,
    rowSpanHeaders,
    height,
    toggleColumnExpandedProps,
    page,
    shouldPagination,
    containSubRow,
    maxHeight,
    tableStyles,
    setHiddenColumns,
    hideColumnsFilter,
    hideColumns,
    visibleColumns,
    tableId,
    hideOptions,
    rowBackColorClass
    } = props;

  // useEffect(() => {
  //   setCurrentColumnOrder(JSON.parse(localStorage.getItem("column_preferences"))?.["columnOrder"]?.[tableId] || [])
  // }, [])

  //To set the column order by including missing columns as well incase of new column added to the table
  useEffect(() => {
    if(allColumns?.length > 1 && currentColumnOrder?.length <= 1) {
      let columns = allColumns.filter((col) => !hideOptions?.includes(col.id));
      let columnOrder =
        JSON.parse(localStorage.getItem("column_preferences"))?.[
          "columnOrder"
        ]?.[tableId] || [];
      if(columnOrder.includes("checkboxForFirstColumn")) {
        let checkboxIndex = columnOrder.indexOf("checkboxForFirstColumn")
        columnOrder.splice(checkboxIndex, 1);
      }
      columns.forEach((col, index) => {
        if(columnOrder[0] === null) {
          index += 1;
        }
        if (!columnOrder.includes(col.id) && !["action", "actions"].includes(col.id)) {
          columnOrder.splice(index, 0, col.id);
        }
      });
      setCurrentColumnOrder(columnOrder);
    }
  }, [allColumns]);

  useEffect(() => {
    let columns = [...currentColumnOrder]
    let id = columns.includes("action") ? "action" : (columns.includes("actions") ? "actions" : "")
    if(id) {
      columns.splice(columns.indexOf(id), 1)
      columns.push(id)
    }
    setColumnOrder(columns)
  }, [currentColumnOrder])

  useEffect(() => {
    let columns = visibleColumns.map(col => col.id)
    let id = columns.includes("action") ? "action" : (columns.includes("actions") ? "actions" : "")
    if (id && (columns.indexOf(id) !== columns.length - 1)) {
      columns.splice(columns.indexOf(id), 1)
      columns.push(id)
      setColumnOrder(columns)
    }
  }, [visibleColumns])

  const rowsToRender = shouldPagination ? page : rows;
  let canFilter = headerGroups.map(val => val.headers.filter(c => c.canFilter))

  const getItemStyle = ({ isDragging, isDropAnimating }, draggableStyle) => ({
    ...draggableStyle,
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    ...(!isDragging && { transform: "translate(0,0)" }),
    ...(isDropAnimating && { transitionDuration: "0.001s" })

    // styles we need to apply on draggables
  });

  React.useEffect(() => {
    if(manualSortBy)
      getManualSortByOptions(sortBy)
  }, [sortBy])

  const currentColOrder = React.useRef();

  const removeColumnOnDropOutside = (column) => {
    // let hideableColumns = getHideColumns(hideColumns)
    // hideableColumns.push(column)
    // console.log("hideableColumns", hideableColumns)
    // setHiddenColumns(hideableColumns)
  }

  const setManualHiddenColumns = (columns) => {
    setHiddenColumns(getHideColumns(hideColumns, tableId).concat(columns))
    getColumnPreferenceRequest("hideColumns")
  }

  const saveColumnOrder = () => {
    let column_preferences = getColumnPreferenceRequest()
    setLoader(true)
    saveColumnOrderApi(column_preferences).then(res => {
      if(res?.data?.status) {
        setLoader(false)
      }
    })
  }

  const getColumnPreferenceRequest = (hideCall) => {
    let leftStickyHeaders = []
    let leftStickyIds = []
    props.columns.forEach(item => {
      getFlatColumns(item) && (leftStickyHeaders = leftStickyHeaders.concat(getFlatColumns(item)))
    })
    leftStickyHeaders.forEach(col => {
      leftStickyIds.push(allColumns.filter(item => item["Header"] === col)[0]?.id)
    })

    leftStickyIds = leftStickyIds.concat(allColumns.filter(item => item.sticky === "left" || item?.parent?.sticky === "left").map(col => col?.id))
    let column_preferences = JSON.parse(localStorage.getItem("column_preferences")) || {}
    let columnIds = [...currentColumnOrder]
    let actionIndex = columnIds.indexOf("action") >= 0 ? columnIds.indexOf("action") : columnIds.indexOf("actions")
    if (actionIndex >= 0) {
      columnIds.splice(actionIndex, 1)
    }
    if(columnIds?.length) {
      columnIds = [...leftStickyIds, ...columnIds]
      columnIds = columnIds.filter(function(item, i, ar){ return ar.indexOf(item) === i; });
    }
    if(!columnIds[0]) {
      columnIds = columnIds.slice(1);
    }
    setCurrentColumnOrder(columnIds)
    let finalOrder = column_preferences.columnOrder
    if(!hideCall) {
      if(!finalOrder) {
        finalOrder = {}
      }
      finalOrder[tableId] = columnIds
    }
    column_preferences = { ...column_preferences, columnOrder: finalOrder }
    localStorage.setItem("column_preferences", JSON.stringify(column_preferences))
    return column_preferences
  }

  const getFlatColumns = (item) => {
    let leftStickyCols = []
    if(item?.sticky === "left") {
      if(item?.columns) {
        item.columns.forEach(col => leftStickyCols.push(col["Header"]))
      }
      else {
        leftStickyCols.push(item["Header"])
      }
    }
    return leftStickyCols
  }

  return (
    <>
      {/* <button onClick={shuffleColumns}>Shuffle</button> */}
      {
        !hideColumnsFilter &&
        <div className="d-flex justify-content-between align-items-end">
          <ColumnSelection hideColumns={hideColumns} tableId={tableId} columnConfig={allColumns.filter(col => !hideOptions?.includes(col.id))} setManualHiddenColumns={setManualHiddenColumns} />
          <div style={{zIndex: "3"}}>
            <button className="btn btn-primary px-2 ml-4" onClick={saveColumnOrder}>
              {saveColOrderLoading ?
                <i class="fa fa-spinner fa-pulse fa-2x"></i>
                : <span>
                  Save Column Order
                </span>
              }

            </button>
            <div className="font-italic pl-3 p-2">To Save rearranged column order</div>
          </div>
        </div>
      }
      <Styles topScrollBar={props.topScrollBar}>
        <div style={props?.embededScroll ? {height: 'fit-content', maxHeight: '50rem' }  : {...props.tableWrapperStyle}}>
          <table
            {...getTableProps({
              style: {
                height: height,
              },
            })}
          >
            <div style={props?.embededScroll ? { position: 'sticky', top: 0, zIndex: '4' } : {...props.headerWrapperStyle}}>
              <thead>
                <>
                  {headerGroups.map((headerGroup) => (
                    <DragDropContext
                      onDragStart={() => {
                        currentColOrder.current = visibleColumns.map((o) => o.id);
                      }}
                      onDragUpdate={(dragUpdateObj, b) => {
                        const colOrder = [...currentColOrder.current];
                        const sIndex = dragUpdateObj.source.index;
                        // if(dragUpdateObj.destination === null) {
                        //   removeColumnOnDropOutside(colOrder[sIndex])
                        // }
                        // else {
                        let dIndex =
                          dragUpdateObj.destination && dragUpdateObj.destination.index;
                        let isDestinationSticky = !!headerGroup?.headers[dIndex]?.sticky || !!headerGroup?.headers[dIndex]?.parent?.sticky
                        // if (isDestinationSticky) {
                        //   dIndex = sIndex
                        // }
                        if (!isDestinationSticky && typeof sIndex === "number" && typeof dIndex === "number") {
                          const copyColOrder = [...colOrder]
                          copyColOrder.splice(Number(sIndex), 1);
                          copyColOrder.splice(Number(dIndex), 0, dragUpdateObj.draggableId);
                          // let actionId = copyColOrder.includes("action") ? "action" : (copyColOrder.includes("actions") ? "actions" : "")
                          // if (actionId) {
                          //   copyColOrder.splice(copyColOrder.indexOf(actionId), 1)
                          //   copyColOrder.push(actionId)
                          // }
                          setCurrentColumnOrder(copyColOrder)
                          // setColumnOrder(copyColOrder)
                        }
                        // }
                      }}
                    >
                      <Droppable droppableId="droppable" direction="horizontal">
                        {(droppableProvided, snapshot) => (
                          <tr
                            className="tr"
                            {...headerGroup.getHeaderGroupProps()}
                            ref={droppableProvided.innerRef}
                          >
                            {headerGroup.headers.map((column, index) => (
                              <Draggable
                                key={column.id}
                                draggableId={column.id}
                                index={index}
                                isDragDisabled={!column.accessor || !!column.sticky || !!column?.parent?.sticky}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <th
                                      className={column?.headers?.[0]?.id == "checkboxForFirstColumn" || column?.headers?.[0]?.id == "radioForFirstColumn" || column.id.includes("checkboxForFirstColumn") ? "th checkboxForFirstColumn" : "th"}
                                      {...column.getHeaderProps()}
                                      {...column.getHeaderProps(
                                        column.getSortByToggleProps &&
                                        column.getSortByToggleProps()
                                      )}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      // {...extraProps}
                                      ref={provided.innerRef}

                                      style={{
                                        cursor: `${!column?.sticky && !column?.parent?.sticky ? "move" : ""}`,
                                        width: `${column.totalWidth}px`,
                                        position: `${column?.sticky || column?.parent?.sticky ? "sticky" : ""}`,
                                        right: `${column?.sticky == "right" || column?.parent?.sticky == "right" ? `${index == headerGroup?.headers.length - 1 ? 0 : (column?.totalLeft - headerGroup?.headers[index - 1].totalLeft)}px` : ''}`,
                                        left: `${column?.sticky == "left" || column?.parent?.sticky == "left" ? `${column?.totalLeft}px` : ''}`,
                                        ...getItemStyle(
                                          snapshot,
                                          provided.draggableProps.style
                                        )
                                      }}
                                    >
                                      {column.render("Header")}
                                      <span>
                                        {column.isSorted ? (
                                          column.isSortedDesc ? (
                                            <i
                                              class="fa fa-chevron-down ml-3"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              class="fa fa-chevron-up ml-3"
                                              aria-hidden="true"
                                            ></i>
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                      {column.enableColumnExpand ? (
                                        <span
                                          onClick={() => {
                                            toggleColumnExpandedProps({
                                              column,
                                            });
                                          }}
                                        >
                                          ➜
                                        </span>
                                      ) : null}
                                    </th>
                                  );
                                }}
                              </Draggable>
                            ))}
                            {droppableProvided.placeholder}
                          </tr>
                        )}
                      </Droppable>
                    </DragDropContext>
                  ))}

                  {canFilter.length && canFilter[canFilter.length - 1]?.length ? headerGroups.map((headerGroup, index) => (index === canFilter.length - 1) && (
                    <tr className="tr" {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className="th"
                          {...column.getHeaderProps()}
                        >
                          <div>{column.canFilter ? column.render("Filter") : null}</div>
                        </th>
                      ))}
                    </tr>
                  )) : null}
                </>
              </thead>
            </div>
            <tbody {...getTableBodyProps()}>
              {rowsToRender.map((row, i) => {
                prepareRow(row);
                for (let j = 0; j < row.allCells.length; j++) {
                  let cell = row.allCells[j];
                  let rowSpanHeader =
                    rowSpanHeaders &&
                    rowSpanHeaders.find((x) => x.id === cell.column.id);
                  if (rowSpanHeader !== undefined) {
                    if (
                      rowSpanHeader.topCellValue === null ||
                      rowSpanHeader.topCellValue !== cell.value
                    ) {
                      cell.isRowSpanned = false;
                      rowSpanHeader.topCellValue = cell.value;
                      rowSpanHeader.topCellIndex = i;
                      cell.rowSpan = 1;
                    } else {
                      rows[rowSpanHeader.topCellIndex].allCells[j].rowSpan++;
                      cell.isRowSpanned = true;
                    }
                  }
                }
                return null;
              })}
              {rowsToRender.map((row) => {
                return (
                  <tr className="tr" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (cell.isRowSpanned) return null;
                      else
                        return (
                          <td
                            className={"td " + (row.original?.showColor ? rowBackColorClass : "bg-white") + (cell?.column?.rowSpan ? " no-border-bottom" : "")}
                            rowSpan={cell.rowSpan}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Styles>
    </>
  );
};
export default TableMarkup;
