import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { intersectionWith, isEqual } from 'lodash';
import { handleSelectAll } from "../../../../utils/SelectAllHandler/SelectAllHander";
import MultiSelect from "../../../../components/Filters/MultiSelect";
import Select from "../../../../components/Filters/SingleSelect";
import Notification from "../../../../components/Notification/Notifications";
import * as Notify from "../../../../components/Notification/Notifications";
import { fetchFilterData, resetAll, resetFiltersOptions } from '../../../ProductStoreStatus/ProductStatus/Actions/ProductStatusAction'
import { levelFiltersLabelFormatter } from "../../../../utils/filterLevelMapping";
import { getUserPreferenceFilter, isMandatory, setUserPreferenceFilter, shouldHideForCurrentClient } from "../../../../utils/commonUtilities";
import AsyncMulti from "../../../../components/Filters/AsyncMulti";
import PropTypes from 'prop-types';

/**
 * View Component with Ada Stand Alone Filters
 */
const Filters = React.memo((props) => {
    const [department, setDepartment] = useState(null);
    const [gender, setGender] = useState(null);
    const [subCat, setSubCat] = useState(null);
    const [dcs, setDCS] = useState(null);
    const [level5, setLevel5] = useState(null);
    const [level6, setLevel6] = useState(null);

    const [style, setStyle] = useState(null);
    const [color, setColor] = useState(null);
    const [request, setRequest] = useState(null)
    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [genderOptions, setGenderOptions] = useState(null);
    const [subCatOptions, setSubCatOptions] = useState(null);
    const [dcsOptions, setDCSOptions] = useState(null);
    const [styleOptions, setStyleOptions] = useState(null);
    const [colorOptions, setColorOptions] = useState(null);
    const [level5Options, setLevel5Options] = useState(null);
    const [level6Options, setLevel6Options] = useState(null);

    useEffect(() => {
        if (props?.l1_name) {
            setDefaultLevel1()
        }
    }, [props.l1_name])

    const setDefaultLevel1 = (noReload) => {
        if(props.selectedDepartments?.length){
            setGender(props.selectedDepartments)
            const l1_name = [props?.l1_name]
            if(!noReload) {
                const l2_name = props.selectedDepartments?.map(dep => dep.value)
                setTimeout(() => {
                    applyFilters([{ label: props?.l1_name, value: props?.l1_name }], props.selectedDepartments)
                }, 1000)
                props.fetchFilterData({ l1_name, l2_name }, "sub_category");
            }
        }
        setDepartment([{ label: props?.l1_name, value: props?.l1_name }])
    }

    useEffect(() => {
        if (props.selectedFilters?.l1_name) {
            setDepartment(props.selectedFilters.l1_name)
        }
        if (props.selectedFilters?.l2_name) {
            setGender(props.selectedFilters?.l2_name)
        }
        if (props.selectedFilters?.l3_name) {
            setSubCat(props.selectedFilters?.l3_name)
        }
        if (props.selectedFilters?.l4_name) {
            setDCS(props.selectedFilters?.l4_name)
        }
        if (props.selectedFilters?.l5_name) {
            setLevel5(props.selectedFilters?.l5_name)
        }
        if (props.selectedFilters?.l6_name) {
            setLevel6(props.selectedFilters?.l6_name)
        }
        if (props.selectedFilters?.style) {
            setStyle(props.selectedFilters?.style)
        }
        if (props.selectedFilters?.color) {
            setColor(props.selectedFilters?.color)
        }
    }, [props.selectedFilters])


    useEffect(() => {
        props.fetchFilterData({}, "department");
        if(props?.selectedDepartments?.length) {
            setGenderOptions(props.selectedDepartments)
        }
        else {
            props.fetchFilterData({ l1_name: [props?.l1_name] }, "gender");
        }
    }, []);

    useEffect(() => {
        if (props.departmentOptions?.length) {
            setDepartmentOptions(() => props.departmentOptions);
        }
        if (props.genderOptions?.length && !props?.selectedDepartments?.length) {
            setGenderOptions(() => props.genderOptions);
        }
        if (props.subCatOptions?.length) {
            setSubCatOptions(() => props.subCatOptions);
        }
        if (props.dcsOptions?.length) {
            setDCSOptions(() => props.dcsOptions);
        }
        if (props.level5Options?.length) {
            setLevel5Options(() => props.level5Options);
        }
        if (props.level6Options?.length) {
            setLevel6Options(() => props.level6Options);
        }
        if (props.styleOptions?.length) {
            setStyleOptions(() => props.styleOptions);
        }
        if (props.colorOptions?.length) {
            setColorOptions(() => props.colorOptions);
        }
    }, [props.departmentOptions, props.genderOptions, props.subCatOptions, props.dcsOptions,
    props.styleOptions, props.colorOptions, props.level5Options, props.level6Options]);

    const resetRestFilters = (p_index) => {
        if (p_index == 0) {
            gender && setGender(null);
            genderOptions && setGenderOptions(null);
        }
        if (p_index == 0 || p_index == 1) {
            subCat && setSubCat(null);
            subCatOptions && setSubCatOptions(null);
        }
        if (p_index == 0 || p_index == 1 || p_index == 2) {
            dcs && setDCS(null);
            dcsOptions && setDCSOptions(null);
        }
        if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3) {
            level5 && setLevel5(null);
            level5Options && setLevel5Options(null)
        }
        if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index == 4) {
            level6 && setLevel6(null);
            level6Options && setLevel6Options(null)
        }
        if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index == 4 || p_index == 5) {
            style && setStyle(null);
            styleOptions && setStyleOptions(null);
        }

        if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index == 4 || p_index == 5 || p_index == 6) {
            color && setColor(null);
            colorOptions && setColorOptions(null);
        }

    };

    const onDepartmentChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], departmentOptions, isEqual);
            setDepartment(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level1': valuesArray[0],
                }
            });
            let l1_name = req_val.map(dep => dep.value)
            props.fetchFilterData({ l1_name }, "gender");
            // props.fetchFilterData({ l1_name }, "style_name");
            // props.fetchFilterData({ l1_name }, "color");
        }
        else {
            setDepartment([val]);
            props.fetchFilterData({ l1_name: [val.value] }, "gender");
            // props.fetchFilterData({ l1_name: [val.value] }, "style_name");
            // props.fetchFilterData({ l1_name: [val.value] }, "color");

        }
        resetRestFilters(0);
    };

    const onGenderChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], genderOptions, isEqual);
            setGender(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level2': valuesArray[0],
                }
            });
            let l1_name = department?.map(dep => dep.value)
            let l2_name = req_val?.map(dep => dep.value)
            props.fetchFilterData({ l1_name, l2_name }, "sub_category");
            // props.fetchFilterData({ l1_name, l2_name }, "style_name");
            // props.fetchFilterData({ l1_name, l2_name }, "color");

        }
        else {
            setGender(val);
            props.fetchFilterData({ l1_name: [department], l2_name: [val] }, "sub_category");
            // props.fetchFilterData({ l1_name: [department], l2_name: [val] }, "style_name");
            // props.fetchFilterData({ l1_name: [department], l2_name: [val] }, "color");

        }
        resetRestFilters(1);
    };

    const onSubCatChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], subCatOptions, isEqual);
            setSubCat(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level3': valuesArray[0],
                }
            });
            let l1_name = department?.map(dep => dep.value)
            let l2_name = gender?.map(dep => dep.value)
            let l3_name = req_val?.map(dep => dep.value)
            props.fetchFilterData({ l1_name, l2_name, l3_name }, "dcs");
            // props.fetchFilterData({ l1_name, l2_name, l3_name }, "style_name");
            // props.fetchFilterData({ l1_name, l2_name, l3_name }, "color");

        }
        else {
            setSubCat(val);
            props.fetchFilterData({ l1_name: [department], l2_name: [...gender], l3_name: [val] }, "dcs");
            // props.fetchFilterData({ l1_name: [department], l2_name: [...gender], l3_name: [val] }, "style_name");
            // props.fetchFilterData({ l1_name: [department], l2_name: [...gender], l3_name: [val] }, "color");

        }
        resetRestFilters(2);
    };

    const onDCSChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], dcsOptions, isEqual);
            setDCS(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level4': valuesArray[0],
                }
            });
            let l1_name = department?.map(dep => dep.value)
            let l2_name = gender?.map(dep => dep.value)
            let l3_name = subCat?.map(dep => dep.value)
            let l4_name = req_val?.map(dep => dep.value)

            !shouldHideForCurrentClient("l5_name") && props.fetchFilterData({ l1_name, l2_name, l3_name, l4_name }, "level5");
            // props.fetchFilterData({ l1_name, l2_name, l3_name, l4_name }, "style_name");
            // props.fetchFilterData({ l1_name, l2_name, l3_name, l4_name }, "color");
        }
        else {
            setDCS(val);
            !shouldHideForCurrentClient("l5_name") && props.fetchFilterData({ l1_name: [department], l2_name: gender, l3_name: subCat, l4_name: [val] }, "level5");

            // props.fetchFilterData(
            //     { l1_name: [department], l2_name: gender, l3_name: subCat, l4_name: [val] }, "style_name"
            // );
            // props.fetchFilterData(
            //     { l1_name: [department], l2_name: gender, l3_name: subCat, l4_name: [val] }, "color"
            // );
        }
        resetRestFilters(3);
    };

    const onLevel5Change = (val, event, that) => {
        let valuesArray = handleSelectAll(val, event, that);
        let req_val = intersectionWith(valuesArray[0], level5Options, isEqual);
        setLevel5(valuesArray[1]);
        resetRestFilters(4);
        setRequest(old => {
            return {
                ...old,
                'level5': valuesArray[0],
            }
        });
        let l1_name = department?.map(dep => dep.value)
        let l2_name = gender?.map(dep => dep.value)
        let l3_name = subCat?.map(dep => dep.value)
        let l4_name = dcs?.map(dep => dep.value)
        let l5_name = req_val?.map(dep => dep.value)

        !shouldHideForCurrentClient("l6_name") && props.fetchFilterData({ l1_name, l2_name, l3_name, l4_name, l5_name }, "level6");
        // props.fetchFilterData({ l1_name, l2_name, l3_name, l4_name, l5_name }, "style_name");
        // props.fetchFilterData({ l1_name, l2_name, l3_name, l4_name, l5_name }, "color");

    };

    const onLevel6Change = (val, event, that) => {
        let valuesArray = handleSelectAll(val, event, that);
        let req_val = intersectionWith(valuesArray[0], level6Options, isEqual);

        setLevel6(valuesArray[1]);
        resetRestFilters(5);
        setRequest(old => {
            return {
                ...old,
                'level6': valuesArray[0],
            }
        });
        let l1_name = department?.map(dep => dep.value)
        let l2_name = gender?.map(dep => dep.value)
        let l3_name = subCat?.map(dep => dep.value)
        let l4_name = dcs?.map(dep => dep.value)
        let l5_name = level5?.map(dep => dep.value)
        let l6_name = req_val?.map(dep => dep.value)

        props.fetchFilterData({ l1_name, l2_name, l3_name, l4_name, l5_name, l6_name }, "style_name");
        // props.fetchFilterData({ l1_name, l2_name, l3_name, l4_name, l5_name, l6_name }, "color");
    }
    const onStyleChange = (val, event, that) => {
        // if (Array.isArray(val)) {
        // let valuesArray = handleSelectAll(val, event, that);
        let req_val = intersectionWith(val, styleOptions, isEqual);
        setStyle(req_val);
        setRequest(old => {
            return {
                ...old,
                'style': req_val,
            }
        });
        let l1_name = department?.map(dep => dep.value)
        let l2_name = gender?.map(dep => dep.value)
        let l3_name = subCat?.map(dep => dep.value)
        let l4_name = dcs?.map(dep => dep.value)
        let l5_name = level5?.map(dep => dep.value)
        let l6_name = level6?.map(dep => dep.value)

        let style = req_val?.map(dep => dep.value)

        props.fetchFilterData({ l1_name, l2_name, l3_name, l4_name, l5_name, l6_name, style }, "color");
        // }
        // else {
        setStyle(req_val);
        setRequest(old => {
            return {
                ...old,
                'style': req_val,
            }
        });
        // props.fetchFilterData(
        //     { level1: [department], level2: [gender], level3: [subCat], level4: [dcs], style: [val] }, "color"
        // );
        // }
        resetRestFilters(6);
    };

    const onColorChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], colorOptions, isEqual);
            setColor(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'color': valuesArray[0],
                }
            });

            // props.fetchFilterData({
            //     level1: [department], level2: gender, level3: subCat,
            //     level4: dcs, style: style, color: req_val,
            // }, "article");
        }
        else {
            setColor(val);
            // props.fetchFilterData(
            //     {
            //         level1: [department], level2: [gender], level3: [subCat], level4: [dcs],
            //         style: [style], color: [val],
            //     },
            //     "article"
            // );
        }
        resetRestFilters(7);
    };

    const applyFilters = (l1_name = department, l2_name = gender) => {
        if (l1_name?.length && l2_name?.length) {
            props.handleFilterRequest({
                l1_name: l1_name && Array.isArray(l1_name) ? l1_name : [l1_name],
                l2_name,
                l3_name: subCat,
                l4_name: dcs,
                l5_name: level5,
                l6_name: level6,
                style,
                color
            });
            // setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id"), "department": department });
        } else {
            Notify.error("Please Select All Mandatory Options!!");
        }
    };

    const resetFilters = () => {
        setDefaultLevel1(true)
        setRequest(null)
        setGender(null);
        setSubCat(null);
        setDCS(null);
        setLevel5(null);
        setLevel6(null);
        setStyle(null);
        setColor(null);
        props.resetData([]);
        props.resetFiltersOptions([]);
    };

    useEffect(() => {
        getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange);
    }, []);

    const getDDLabel = (label) => label ? label : ""
    return (
        <>
            {/* <Notification /> */}

            <section className="row section">
                {/* <div className="row" style={{ flexDirection: "column", marginTop: "0" }}> */}
                {/* <div className="row"> */}
                <div className="col-md-3 col-md-3-dd required">
                    <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1 || levelFiltersLabelFormatter("level1")}</label>
                    <MultiSelect
                        isDisabled
                        name="department"
                        dropdownValue={department}
                        onDropDownChange={onDepartmentChange}
                        placeHolder={`Select ${props.filterLabels?.level1 || levelFiltersLabelFormatter("level1")}`}
                        optionsArray={departmentOptions?.length ? departmentOptions : []}
                    />
                </div>
                <div className={`col-md-3 col-md-3-dd ${isMandatory("l2_name")}`}>
                    <label className="fnt-md fnt-bold">{props.filterLabels?.level2 || levelFiltersLabelFormatter("level2")}</label>
                    <MultiSelect
                        name="gender"
                        dropdownValue={gender}
                        onDropDownChange={onGenderChange}
                        placeHolder={`Select ${getDDLabel(props.filterLabels?.level2)}`}
                        optionsArray={genderOptions?.length ? genderOptions : []}
                    />
                </div>
                <div className="col-md-3 col-md-3-dd">
                    <label className="fnt-md fnt-bold">{props.filterLabels?.level3 || levelFiltersLabelFormatter("level4")}</label>
                    <MultiSelect
                        name="subCat"
                        dropdownValue={subCat}
                        onDropDownChange={onSubCatChange}
                        placeHolder={`Select ${getDDLabel(props.filterLabels?.level3)}`}
                        optionsArray={subCatOptions?.length ? subCatOptions : []}
                    />
                </div>
                <div className="col-md-3 col-md-3-dd">
                    <label className="fnt-md fnt-bold">{props.filterLabels?.level4 || levelFiltersLabelFormatter("level3")}</label>
                    <MultiSelect
                        name="dcs"
                        dropdownValue={dcs}
                        onDropDownChange={onDCSChange}
                        placeHolder={`Select ${getDDLabel(props.filterLabels?.level4)}`}
                        optionsArray={dcsOptions?.length ? dcsOptions : []}
                    />
                </div>
                {
                    !shouldHideForCurrentClient("l5_name") &&
                    <div className="col-md-3 col-md-3-dd">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level5}</label>
                        <MultiSelect
                            name="level5"
                            dropdownValue={level5}
                            onDropDownChange={onLevel5Change}
                            placeHolder={`Select ${props.filterLabels?.level5}`}
                            optionsArray={level5Options?.length ? level5Options : []}
                        />
                    </div>
                }
                {
                    !shouldHideForCurrentClient("l6_name") &&
                    <div className="col-md-3 col-md-3-dd">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level6}</label>
                        <MultiSelect
                            name="level6"
                            dropdownValue={level6}
                            onDropDownChange={onLevel6Change}
                            placeHolder={`Select ${props.filterLabels?.level6}`}
                            optionsArray={level6Options?.length ? level6Options : []}
                        />
                    </div>
                }
                <div className="col-md-3 col-md-3-dd">
                    <label className="fnt-md fnt-bold">Style</label>
                    <AsyncMulti
                        name="style"
                        dropdownValue={style}
                        onDropDownChange={onStyleChange}
                        placeHolder={'Select Style'}
                        optionsArray={styleOptions?.length ? styleOptions : []}
                    />
                </div>
                <div className="col-md-3 col-md-3-dd mt-3">
                    <label className="fnt-md fnt-bold">Color</label>
                    <MultiSelect
                        name="dcs"
                        dropdownValue={color}
                        onDropDownChange={onColorChange}
                        placeHolder={'Select Color'}
                        optionsArray={colorOptions?.length ? colorOptions : []}
                    />
                </div>

                <div style={{ marginTop: "10px" }}>
                    <button
                        onClick={() => {applyFilters()}}
                        className="btn btn-primary filter-constraints-mode"
                        title="Apply filters"
                    >
                        <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
                    </button>
                    <button
                        onClick={() => resetFilters()}
                        className="btn undo-constraints-mode"
                        title="Reset filters"
                    >
                        <i className="fa fa-undo mr-2"></i>Reset
                    </button>
                </div>
                <div className="col-md-3 required fnt-md pt-5" style={{ color: "red" }}>
                    <label></label>Fields are mandatory
                </div>
                {/* </div> */}
                {/* </div> */}
            </section>
        </>
    );

}
);

const mapStateToProps = ({ productStatus }) => ({
    departmentOptions: productStatus.departmentOptions,
    genderOptions: productStatus.genderOptions,
    subCatOptions: productStatus.rbuOptions,
    dcsOptions: productStatus.dcsOptions,
    level5Options: productStatus.level5Options,
    level6Options: productStatus.level6Options,
    filterLabels: productStatus.filterlabels,
    styleOptions: productStatus.styleOptions,
    colorOptions: productStatus.colorOptions,
})

const mapDispatchToProps = (dispatch) => ({
    resetAll: () => dispatch(resetAll()),
    resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
    fetchFilterData: (payload, filterKey, filterType) =>
        dispatch(fetchFilterData(payload, filterKey, filterType))
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);

Filters.propTypes = {
    l1_name: PropTypes.object,
    selectedDepartments: PropTypes.array,
    resetData: PropTypes.func
};